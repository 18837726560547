import {
  SET_UNAUTHENTICATED,
  SET_USER,
  LOADING_USER,
  STOP_LOADING_USER,
  SET_USER_ERROR,
} from "../types";
import axios from "axios";
import Cookie from "js-cookie";

let logout;
const setAuthHeader = (data) => {
  const token = `Bearer ${data.token}`;
  Cookie.set("tkn", token, { expires: 7 });
  axios.defaults.headers.common["Authorization"] = token;
};

function getCookieValue(a) {
  var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}
const logOutIfTokenExp = (logoutTime) => (dispatch) => {
  console.log("Loging out after " + logoutTime / 1000 + " Sec");
  logout = setTimeout(() => {
    clearTimeout(logout);
    console.log("Token expired, loging out");
    dispatch(logoutUser());
  }, logoutTime);
};
export const loginUser = (userData, history) => (dispatch) => {
  dispatch({
    type: SET_USER_ERROR,
    payload: null,
  });
  dispatch({ type: LOADING_USER });
  axios
    .post("/api/login", userData)
    .then((res) => {
      localStorage.setItem("lou", res.data.logout);
      dispatch({ type: SET_USER, payload: res.data.user });
      dispatch({ type: STOP_LOADING_USER });
      const logoutTime = getCookieValue("lo");
      dispatch(logOutIfTokenExp(logoutTime));
      // console.log(document.cookie);
      history.push("/");
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({
        type: SET_USER_ERROR,
        payload: err.response
          ? err.response.data
          : "Klaida, prisijungti nepavyko.",
      });
      dispatch({ type: STOP_LOADING_USER });
    });
};

export const checkUser = (history) => (dispatch) => {
  dispatch({ type: LOADING_USER });

  axios
    .get("/api/user")
    .then((res) => {
      const logoutTime = getCookieValue("lo");
      dispatch(logOutIfTokenExp(logoutTime));
      dispatch({ type: SET_USER, payload: res.data.user });
      dispatch({ type: STOP_LOADING_USER });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_USER });
    });
};

export const logoutUser = () => (dispatch) => {
  axios
    .get("/api/logout")
    .then((res) => {
    })
    .catch((err) => {
    });
  Cookie.remove("lo");
  Cookie.remove("tkn");
  Cookie.remove("tokenn");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
};
