// import "./styles/schoolStats.css";
import "../App.css";
import React, { useEffect, useState, useMemo, forwardRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "./ContainerWrapper";
import MaterialTable from "material-table";
import { TableCell } from "@material-ui/core";

import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Export from "@material-ui/icons/GetApp";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { tableData, treeTableData } from "../redux/actions/statisticsDataActions";
import { MultiSelect, SelectPanel } from "react-multi-select-component";
import moment from "moment";
import Button from "@material-ui/core/Button";

import axios from "axios";
import Alert from "../components/AlertMsg";

import regionData from './data_files/rajonu_sarasas.json';

import * as XLSX from "xlsx/xlsx.mjs";

import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import { red } from "@material-ui/core/colors";

// export function AlertDialog() {
//     const [open, setOpen] = useState(false);

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     return (
//         <div>
//             <Dialog
//                 open={open}
//                 onClose={handleClose}
//                 aria-labelledby="alert-dialog-title"
//                 aria-describedby="alert-dialog-description"
//             >
//                 <DialogTitle id="alert-dialog-title">
//                     {"Testas"}
//                 </DialogTitle>
//                 <DialogContent>
//                     <DialogContentText id="alert-dialog-description">
//                         Testas testas testas
//                     </DialogContentText>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleClose}>Testas</Button>
//                 </DialogActions>
//             </Dialog>
//         </div>
//     )
// }

export const StatsTreeData = ({rowData, selectedTime}) => {
    const dispatch = useDispatch();
    var treeSchoolsTableData = null;
    const state = useSelector((state) => state.data);
    const [data, setData] = useState(null);
    const [err, setErr] = useState(null);
    const [loading, setLoading] = useState(false);

    async function formatData(){
        treeSchoolsTableData = await treeTableData(rowData.schools, selectedTime);
        // if(selectedTime == null || rowData == null){

        // } else{
            if (treeSchoolsTableData != null){
                setData(treeSchoolsTableData);
            }
        // }
    }

    useEffect(() => {
        formatData();
    }, []);

    return (
        <div style={{ textAlign: "center", width: "100%" }}>
            {data ? (
                <div
                    style={{
                        textAlign: "center",
                        width: "100%",
                    }}
                >
                    <TableContainer>
                        <Table aria-label="simple table" style={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Mokykla</TableCell>
                                    <TableCell align="center">Įdiegtas AP kiekis</TableCell>
                                    {/* <TableCell align="center">Vidutinis RX srautas (parsiuntimas) (Mbps)</TableCell> */}
                                    {/* <TableCell align="center">Maksimalus RX srautas (parsiuntimas) (Mbps)</TableCell> */}
                                    {/* <TableCell align="center">Vidutinis RX srautas (išsiuntimas) (Mbps)</TableCell> */}
                                    {/* <TableCell align="center">Maksimalus RX srautas (išsiuntimas) (Mbps)</TableCell> */}
                                    {/* <TableCell align="center">Vidutinis prisijungusių įrenginių sk.</TableCell> */}
                                    {/* <TableCell align="center">Maksimalus prisijungusių įrenginių sk.</TableCell> */}
                                    <TableCell align="center">Vid. parsiuntimo srautas (RX) (Mbps)</TableCell>
                                    <TableCell align="center">Maks. parsiuntimo srauats (RX) (Mbps)</TableCell>
                                    <TableCell align="center">Vid. išsiuntimo srautas (RX) (Mbps)</TableCell>
                                    <TableCell align="center">Maks. išsiuntimo srautas (RX) (Mbps)</TableCell>
                                    <TableCell align="center">Vid. prisijungusių įrenginių sk.</TableCell>
                                    <TableCell align="center">Maks. prisijungusių įrenginių sk.</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            {item && item["Mokykla"]}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item["Įdiegtas AP kiekis"] && item["Įdiegtas AP kiekis"]}
                                        </TableCell>
                                        <TableCell align="center">
                                            {/* {item["Vidutinis RX srautas (parsiuntimas) (Mbps)"] && item["Vidutinis RX srautas (parsiuntimas) (Mbps)"]} */}
                                            {item["Vid. parsiuntimo srautas (RX) (Mbps)"] && item["Vid. parsiuntimo srautas (RX) (Mbps)"]}
                                        </TableCell>
                                        <TableCell align="center">
                                            {/* {item["Maksimalus RX srautas (parsiuntimas) (Mbps)"] && item["Maksimalus RX srautas (parsiuntimas) (Mbps)"]} */}
                                            {item["Maks. parsiuntimo srautas (RX) (Mbps)"] && item["Maks. parsiuntimo srautas (RX) (Mbps)"]}
                                        </TableCell>
                                        <TableCell align="center">
                                            {/* {item["Vidutinis RX srautas (išsiuntimas) (Mbps)"] && item["Vidutinis RX srautas (išsiuntimas) (Mbps)"]} */}
                                            {item["Vid. išsiuntimo srautas (RX) (Mbps)"] && item["Vid. išsiuntimo srautas (RX) (Mbps)"]}
                                        </TableCell>
                                        <TableCell align="center">
                                            {/* {item["Maksimalus RX srautas (išsiuntimas) (Mbps)"] && item["Maksimalus RX srautas (išsiuntimas) (Mbps)"]} */}
                                            {item["Maks. išsiuntimo srautas (RX) (Mbps)"] && item["Maks. išsiuntimo srautas (RX) (Mbps)"]}
                                        </TableCell>
                                        <TableCell align="center">
                                            {/* {item["Vidutinis prisijungusių įrenginių sk."] && item["Vidutinis prisijungusių įrenginių sk."]} */}
                                            {item["Vid. prisijungusių įrenginių sk."] && item["Vid. prisijungusių įrenginių sk."]}
                                        </TableCell>
                                        <TableCell align="center">
                                            {/* {item["Maksimalus prisijungusių įrenginių sk."] && item["Maksimalus prisijungusių įrenginių sk."]} */}
                                            {item["Maks. prisijungusių įrenginių sk."] && item["Maks. prisijungusių įrenginių sk."]}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ) : err ? (
                <p style={{ color: "red" }}>
                    <Alert msgType={"error"} txt={err} style={{ width: "50%" }} />
                </p>
            ) : (
                <p style={{ width: "100%" }}>Kraunama...</p>
            )}
        </div>
    );
};


const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (<ChevronRight {...props} ref={ref} />)),
    Export: forwardRef((props, ref) => (<Export {...props} ref={ref} />)),
}
function SchoolStats( { device }){
    const dispatch = useDispatch();
    const state = useSelector((state) => state.data);
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dateTo, setDateTo] = useState(moment());
    const [dateFrom, setDateFrom] = useState(moment().subtract(1, "days"));
    const [buttonPressed, setButtonStatus] = useState(false);
    const [buttonActive, setButtonAvailability] = useState(false);
    const [notSelected, setNotSelected] = useState(false);
    
    const [selectedDistr, setSelectedDistr] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedTime, setSelectedTime] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);

    const [prepDownload, setPrepDownload] = useState(false);
    const [prepDownloadHelp, setPrepDownloadHelp] = useState(true);
    const [workBook, setWorkBook] = useState([]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    var dropdownData = null;
    var schoolsTableData = null;
    const timeDropdown = [
        // { value: "1d", label: "Šiandien" },
        // { value: "7d", label: "Savaitė" },
        // { value: "30d", label: "Mėnesis" },
        // { value: "12m", label: "Metai" },
        { value: "1d", label: "< 24h" },
        { value: "7d", label: "< 7d." },
        { value: "30d", label: "< 30d." },
        { value: "12m", label: "< 12mėn." },
    ]

    async function downloadExcel(){

        setPrepDownload(true);
        var treeSchoolsTableData = [];
        var schoolsDataHelper = null;
        var schoolsDataForExcel = [];
        var m = 0;
        // Surenkama informacija apie mokyklas, kurios lenteleje yra kaip "medis"
        for(var i = 0; i < state.schoolsStatisticsTableData.length; i++){
            schoolsDataForExcel[i] = state.schoolsStatisticsTableData[i];
            if(schoolsDataForExcel[i].schools){
                schoolsDataHelper = await treeTableData(schoolsDataForExcel[i].schools, selectedTime);
                for(var j = 0; j < schoolsDataHelper.length; j++){
                    treeSchoolsTableData[m] = schoolsDataHelper[j];
                    m += 1;
                }                
                delete schoolsDataForExcel[i].schools;
            }
            delete schoolsDataForExcel[i].expandable;
        }
        // Apjungiami seni lenteles ir nauji ("medzio") duomenys
        var schoolsCount = state.schoolsStatisticsTableData.length;
        
        for(var i = 0; i < treeSchoolsTableData.length; i++){
            schoolsDataForExcel[schoolsCount] = treeSchoolsTableData[i];
            delete schoolsDataForExcel[schoolsCount].expandable;
            schoolsCount += 1;
        }

        setPrepDownload(false);
        schoolsDataForExcel.sort((a,b) => a.Mokykla.localeCompare(b.Mokykla));

        const newData = schoolsDataForExcel.map(row =>{
            delete row.tableData
            return row
        });
        
        
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "mokyklos");

        //Buffer
        let buf = XLSX.write(workBook, {bookType: "xlsx", type: "buffer"});

        //Binary string
        XLSX.write(workBook, {bookType: "xlsx", type: "binary"});

        var currentDate = moment().format("YYYY-MM-DD");
        var fileName = currentDate + " mokyklu statistikos.xlsx";
        //Download
        XLSX.writeFile(workBook, fileName)
    }

    const valueRendererSchools = (selected) => {
        if (selected.length > 1) {
            return selected.length + " pasirinktos mokyklos...";
        }
    }

    const valueRendererRegions = (selected) => {
        if (selected.length > 1) {
            return selected.length + " pasirinkti rajonai...";
        }
    }

    async function handleRegionChange(selRegion){    
        var data = [];
        for(var i = 0; i < selRegion.length; i++){
            var res = await searchDataByRegion(selRegion[i].value[0].raktas);
            if(res != null){
                res.forEach(element => {
                    data.push(element);
                });
            }
        }
        dispatch({type: "SET_SEARCH_DATA", payload: data });
        await formSchoolsDropdown(data);
        setSelectedRegion(selRegion);
    }

    const handleSchoolChange = (selSchool) => {
        if(selSchool == null){
            setSelected(null);
        } else {
            setSelected(selSchool);
        }
    }

    const handleTimeChange = (selTime) => {
        if(selTime.length != 1){
            var t = [selTime[1]];
            setSelectedTime(t);
        } else if(selTime.length == 1){
            setSelectedTime(selTime);
        }
    }

    async function handleButtonClick(){

        setButtonStatus(true);
        setLoading(true);
        setButtonAvailability(true);

        dispatch({ type: "SCHOOLS_STATISTICS_TABLE_DATA", payload: null})

        if(selectedTime.length == 0 || selected.length == 0){
            setNotSelected(true);
            setButtonStatus(false);
            setButtonAvailability(false);
        }else{
            setNotSelected(false);
            schoolsTableData = await tableData(selected, selectedTime);
            if(schoolsTableData != null){
                setButtonStatus(false);
                dispatch({ type: "SET_LOADING", payload: "tableData" });
                dispatch({ type: "SCHOOLS_STATISTICS_TABLE_DATA", payload: schoolsTableData})
                setButtonAvailability(false);
            }
        }
    }

    // useEffect(() => {
    //     schoolsTableData = null;
    //     dispatch({ type: "SCHOOLS_STATISTICS_TABLE_DATA", payload: null});
    // }, []);

    useEffect(() => {
        formData();
        dispatch({ type: "SCHOOLS_STATISTICS_TABLE_DATA", payload: null});
    }, []);

    async function formData(){
        const data = await searchData();
        formSchoolsDropdown(data);
    }

    async function searchDataByRegion(region){
        var data = null;
        dispatch({type: "SET_SEARCH_DATA", payload: [] });
        dispatch({ type: "FIRST_SCHOOLS_STATISTICS_DROPDOWN_SCHOOLS", payload: null });
        await axios
            .post("api/netboxsitesbyregion", {region})
            .then((res) => {
                data = res.data.result.results;
            })
            .catch((err) => {
                dispatch({ type: "SET_LOADING", payload: false });
            });
        return data;
    }

    async function searchData(){
        var data = null;
        dispatch({ type: "SET_SEARCH_DATA", payload: [] });
        await axios
            .get("/api/netboxsites")
            .then((res) => {
                dispatch({
                    type: "SET_SEARCH_DATA",
                    payload: res.data.result.results,
                });
                data = res.data.result.results;
            })
            .catch((err) => {
                dispatch({ type: "SET_LOADING", payload: false });
            });
        return data;
    }

    useEffect(() => {
        var dropdown = [];
        for(var i = 0; i < regionData.length; i++){
            var v = [
                {
                    eil_nr: regionData[i].eil_nr,
                    pavadinimas: regionData[i].pavadinimas,
                    raktas: regionData[i].key
                }
            ]
            dropdown[i] = {
                value: v,
                label: regionData[i].pavadinimas
            }
        }
        dispatch({ type: "SECOND_SCHOOLS_STATISTICS_DROPDOWN_REGIONS", payload: dropdown })
    }, []);

    const formSchoolsDropdown = (data) => {
        const role = user.user && user.user.role;
        const searchData = data;
        var dropdown = [];
        var created = [];
        for(var i = 0; i < searchData.length; i++) {
            if(searchData[i].custom_fields.pavadinimas === null){
                var v = [
                    {
                        name: searchData[i].name,
                        id: searchData[i].id,
                        ap_sk: searchData[i].custom_fields.ap_sk,
                        mok_sk: searchData[i].custom_fields.mok_sk,
                        slug: searchData[i].slug,
                        schools: null,
                    }
                ]
                dropdown[i] = {
                    value: v,
                    label: searchData[i].name
                }
            } 
            else {
                var created_stat = false;
                var schools = [];
                if(created.length > 0){
                    for(var j = 0; j < created.length; j++) {
                        if(searchData[i].custom_fields.pavadinimas === created[j].custom_fields.pavadinimas){
                            created_stat = true;
                            break;
                        }
                    }
                }
                if(created_stat == false){
                    for(var j = 0; j < searchData.length; j++) {
                        if(searchData[j].custom_fields.pavadinimas === searchData[i].custom_fields.pavadinimas){
                            schools.push(searchData[j]);
                            created.push(searchData[j]);
                        }
                    }
                    var v = [
                        {
                            name: searchData[i].custom_fields.pavadinimas,
                            id: '',
                            ap_sk: '',
                            mok_sk: searchData[i].custom_fields.mok_sk,
                            schools: schools,
                            slug: searchData[i].slug,
                        }
                    ]
                    dropdown[i] = {
                        value: v,
                        label: searchData[i].custom_fields.pavadinimas
                    }
                }

            }
            
            
        }
        dispatch({ type: "FIRST_SCHOOLS_STATISTICS_DROPDOWN_SCHOOLS", payload: dropdown })
    }

    return (
        <div className="anim-fade-in">
            <div>
                <div
                    style={{
                        width: "99%",
                        minHeight: "90vh",
                        padding: 10,
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "100px",
                        transition: "all 0.1s ease-in-out",
                    }}
                >
                    <Wrapper
                        title="Statistika"
                        sub="Statistika"
                        styles={{
                            marginBottom: 15,
                        }}
                    >
                        {prepDownload && (
                            <p style={{
                                color: "blue"
                            }}>
                                <Alert 
                                    msgType={"info"}
                                    txt={
                                        "Prašome palaukti, atsiuntimas ruošiamas..."
                                    } 
                                    style={{ width: "50%" }}
                                />
                                <LinearProgress />
                            </p>
                        )}
                        {notSelected && (
                            <p style={{
                                color: "red" 
                            }}>
                                <Alert msgType={"error"} txt={"Prašome pasirinkti mokyklą (-as) ir laikotarpį."} style={{ width: "50%" }} />
                            </p>
                        )}
                        {/* {!notSelected && (
                            <p style={{
                                color: "red" 
                            }}>
                                <Alert msgType={"error"} txt={"Done!"} style={{ width: "50%" }} />
                            </p>
                        )} */}
                        {state.firstSchoolsStatsDropdownSchools == null && (
                            <p
                                style={{
                                    height: "3rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                Kraunama...
                                <CircularProgress size={25} style={{ marginLeft: 10 }} />
                            </p>
                        )}
                        {state.firstSchoolsStatsDropdownSchools != null && state.secondSchoolsStatsDropdownRegions != null && (
                        
                            <>
                            <div
                                style={{
                                    marginTop: 45,
                                    marginBottom: 90
                                }}
                            >
                                <table
                                    border="0"
                                    width="100%"
                                >
                                    <col style={{ width: "30%" }} />
                                    <col style={{ width: "30%" }} />
                                    <col style={{ width: "20%" }} />
                                    <col style={{ width: "20%" }} />
                                    <thead>
                                        <tr>
                                            <th>Pasirinkite rajoną (-us):</th>
                                            <th>Pasirinkite mokyklą (-as):</th>
                                            <th>Laikotarpis:</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{
                                                paddingLeft: 30,
                                            }}>
                                                <MultiSelect
                                                    flexDirection="false"
                                                    options={state.secondSchoolsStatsDropdownRegions}
                                                    value={selectedRegion}
                                                    onChange={(selectedRegion) => {
                                                        handleRegionChange(selectedRegion);
                                                        }
                                                    }
                                                    labelledBy="Selected"
                                                    overrideStrings={{
                                                        "allItemsAreSelected": "Pasirinkti visi rajonai",
                                                        "clearSearch": "Išvalyti paiešką",
                                                        "clearSelected": "Išvalyti pasirinkimus",
                                                        "noOptions": "Nėra pasirinkimų",
                                                        "search": "Paieška",
                                                        "selectAll": "Pasirinkti viską",
                                                        "selectAllFiltered": "Pasirinkti viską (išfiltruoti)",
                                                        "selectSomeItems": "Pasirinkti...",
                                                        "create": "Sukurti"
                                                    }}
                                                    valueRenderer={valueRendererRegions}
                                                />
                                            </td>
                                            <td>
                                                <MultiSelect
                                                    flexDirection="false"
                                                    options={state.firstSchoolsStatsDropdownSchools}
                                                    value={selected}
                                                    onChange={(selected) => {
                                                            handleSchoolChange(selected);
                                                        }
                                                    }
                                                    labelledBy="Select"
                                                    overrideStrings={{
                                                        "allItemsAreSelected": "Pasirinktos visos mokyklos",
                                                        "clearSearch": "Išvalyti paiešką",
                                                        "clearSelected": "Išvalyti pasirinkimus",
                                                        "noOptions": "Nėra pasirinkimų",
                                                        "search": "Paieška",
                                                        "selectAll": "Pasirinkti viską",
                                                        "selectAllFiltered": "Pasirinkti viską (išfiltruoti)",
                                                        "selectSomeItems": "Pasirinkti...",
                                                        "create": "Sukurti"
                                                    }}
                                                    valueRenderer={valueRendererSchools}
                                                />
                                            </td>
                                            <td>
                                                <MultiSelect
                                                    hasSelectAll={false}
                                                    closeOnChangedValue={true}
                                                    disableSearch={true}
                                                    valueRenderer={false}
                                                    flexDirection="false"
                                                    options={timeDropdown}
                                                    value={selectedTime}
                                                    onChange={(selectedTime) => {
                                                        if(selectedTime.length != 0) {
                                                            handleTimeChange(selectedTime);
                                                        }
                                                    }}
                                                    labelledBy="Select"
                                                    overrideStrings={{
                                                        "allItemsAreSelected": "Pasirinkti visi laikai",
                                                        "clearSearch": "Išvalyti paiešką",
                                                        "clearSelected": "Išvalyti pasirinkimus",
                                                        "noOptions": "Nėra pasirinkimų",
                                                        "search": "Paieška",
                                                        "selectAll": "Pasirinkti viską",
                                                        "selectAllFiltered": "Pasirinkti viską (išfiltruoti)",
                                                        "selectSomeItems": "Pasirinkti...",
                                                        "create": "Sukurti"
                                                    }} />
                                            </td>
                                            <td>
                                                <Button
                                                    style={{
                                                        height: 35,
                                                        minWidth: 120,
                                                        outline: "none",
                                                    }}
                                                    variant="contained"
                                                    disabled={buttonActive}
                                                    onClick={() =>
                                                        handleButtonClick()
                                                    }
                                                >
                                                    Gauti
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </>
                        )}
                        {loading && buttonPressed && (
                            <p
                                style={{
                                    height: "3rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                Kraunama...
                                <CircularProgress size={25} style={{ marginLeft: 10 }} />
                            </p>
                        )}
                        {state.loading === "tableData"  && state.schoolsStatisticsTableData && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                }}
                            >
                                <MaterialTable
                                    style={{
                                        width: "90%",
                                        padding: 50,
                                        marginLeft: "5%",
                                        marginRight: "5%",
                                        textAlign: "center",
                                        boxShadow:
                                            "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px, -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                                    }}
                                    localization={{
                                        header: {
                                            actions: "",
                                        },
                                        toolbar: {
                                            searchPlaceholder: "Paieška...",
                                            searchTooltip: "Paieška",
                                        },
                                        body: {
                                            emptyDataSourceMessage: "Įrašų nerasta.",
                                        },
                                        pagination: {
                                            labelRowsSelect: "Eil.",
                                            firstTooltip: "Pirmas",
                                            previousTooltip: "Ankstesnis",
                                            nextTooltip: "Sekantis",
                                            lastTooltip: "Paskutinis",
                                        }
                                    }}
                                    
                                    icons={tableIcons}
                                    columns={[
                                        {
                                            title: "Mokykla",
                                            //field: "name",
                                            field: "Mokykla"
                                        },
                                        {
                                            title: "Mokinių skaičius",
                                            //field: "mok_sk",
                                            field: "Mokinių skaičius"
                                        },
                                        {
                                            title: "Įdiegtas AP kiekis",
                                            //field: "ap_count",
                                            field: "Įdiegtas AP kiekis"
                                        },
                                        {
                                            //title: "Vidutinis RX srautas (parsiuntimas) (Mbps)",
                                            //field: "received_avg",
                                            title: "Vid. parsiuntimo srautas (RX) (Mbps)",
                                            //field: "Vidutinis RX srautas (parsiuntimas) (Mbps)"
                                            field: "Vid. parsiuntimo srautas (RX) (Mbps)"
                                        },
                                        {
                                            //title: "Maksimalus RX srautas (parsiuntimas) (Mbps)",
                                            //field: "received_max",
                                            title: "Maks. parsiuntimo srautas (RX) (Mbps)",
                                            //field: "Maksimalus RX srautas (parsiuntimas) (Mbps)"
                                            field: "Maks. parsiuntimo srautas (RX) (Mbps)"
                                        },
                                        {
                                            //title: "Vidutinis RX srautas (išsiuntimas) (Mbps)",
                                            //field: "transmited_avg",
                                            title: "Vid. išsiuntimo srautas (RX) (Mbps)",
                                            //field: "Vidutinis RX srautas (išsiuntimas) (Mbps)"
                                            field: "Vid. išsiuntimo srautas (RX) (Mbps)"
                                        },
                                        {
                                            //title: "Maksimalus RX srautas (išsiuntimas) (Mbps)",
                                            // field: "transmited_max",
                                            title: "Maks. išsiuntimo srautas (RX) (Mbps)",
                                            //field: "Maksimalus RX srautas (išsiuntimas) (Mbps)"
                                            field: "Maks. išsiuntimo srautas (RX) (Mbps)"
                                        },
                                        {
                                            //title: "Vidutinis prisijungusių įrenginių sk.",
                                            // field: "vid_users",
                                            title: "Vid. prisijungusių įrenginių sk.",
                                            //field: "Vidutinis prisijungusių įrenginių sk."
                                            field: "Vid. prisijungusių įrenginių sk."
                                        },
                                        {
                                            //title: "Maksimalus prisijungusių įrenginių sk.",
                                            // field: "max_users",
                                            title: "Maks. prisijungusių įrenginių sk.",
                                            //field: "Maksimalus prisijungusių įrenginių sk."
                                            field: "Maks. prisijungusių įrenginių sk."
                                        }
                                    ]}
                                    data={state.schoolsStatisticsTableData}
                                    options={{
                                        // actionsColumnIndex: -1,
                                        sorting: false,
                                        draggable: false,
                                        pageSizeOptions: [8, 15, 20, 100],
                                        pageSize: 8,
                                        columnsButtons: false,
                                        exportButton: false,
                                        filtering: false,
                                        showTitle: false,
                                        headerStyle: {
                                            color: "black",
                                            fontWeight: "bold",
                                        },
                                    }}
                                    actions={[
                                        {
                                            icon:()=><Export/>,
                                            tooltop:"Eksportuoti į Excel",
                                            onClick:()=>{downloadExcel();},//, handleClickOpen},
                                            isFreeAction:true
                                        }
                                    ]}
                                    // actions={[
                                    //     {
                                    //         icon: "save",
                                    //         tooltop: "Save",
                                    //         isFreeAction: true,
                                    //     }
                                    // ]}
                                    detailPanel= {
                                        [
                                            rowData => ({
                                                disabled: rowData.expandable,
                                                //hidden: rowData.expandable,
                                                icon: () => {
                                                    if(!rowData.expandable){
                                                        return <ChevronRight />
                                                    } else {
                                                        return false
                                                    }
                                                },
                                                // hidden: true,
                                                render: () => {
                                                    if(rowData["Vid. parsiuntimo srautas (RX) (Mbps)"] === '-' && rowData["Maks. parsiuntimo srautas (RX) (Mbps)"] === '-' && rowData["Vid. išsiuntimo srautas (RX) (Mbps)"] === '-' && rowData["Maks. išsiuntimo srautas (RX) (Mbps)"] === '-' && rowData["Vid. prisijungusių įrenginių sk."] === '-' && rowData["Maks. prisijungusių įrenginių sk."] === '-'){
                                                        return (
                                                            <div
                                                                style={{
                                                                    backgroundColor: "#f8f8f8",
                                                                    padding: 20,
                                                                }}
                                                            >
                                                                <StatsTreeData
                                                                    rowData={rowData}
                                                                    selectedTime={selectedTime}
                                                                />
                                                            </div>
                                                        );
                                                    } else {
                                                        return false
                                                    }
                                                },
                                            })
                                        ]
                                    }

                                    // detailPanel={[
                                    //     {
                                    //         tooltip: "Detaliau",
                                    //         rowData => ({
                                                
                                    //         })
                                    //         // render: (rowData) => {
                                    //         //     if(rowData["Vidutinis RX srautas (parsiuntimas) (Mbps)"] === '-' && rowData["Maksimalus RX srautas (parsiuntimas) (Mbps)"] === '-' && rowData["Vidutinis RX srautas (išsiuntimas) (Mbps)"] === '-' && rowData["Maksimalus RX srautas (išsiuntimas) (Mbps)"] === '-' && rowData["Vidutinis prisijungusių įrenginių sk."] === '-' && rowData["Maksimalus prisijungusių įrenginių sk."] === '-'){
                                    //         //         return (
                                    //         //             <div
                                    //         //                 style={{
                                    //         //                     backgroundColor: "#f8f8f8",
                                    //         //                     padding: 20,
                                    //         //                 }}
                                    //         //             >
                                    //         //                 <StatsTreeData
                                    //         //                     rowData={rowData}
                                    //         //                     selectedTime={selectedTime}
                                    //         //                 />
                                    //         //             </div>
                                    //         //         );
                                    //         //     }
                                    //         // },
                                    //     },
                                    // ]}
                                >
                                </MaterialTable>
                            </div>
                        )}
                    </Wrapper>
                </div>
            </div>
        </div>
    );
}

export default SchoolStats;